<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="단위공정"
            name="subProcessCd"
            v-model="searchParam.subProcessCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="설비명"
            name="equipmentName"
            placeholder="설비명"
            v-model="searchParam.equipmentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-equip-class
            :editable="editable"
            label="설비유형"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="설비등급"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="discardFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="discardFlag"
            label="폐기여부"
            v-model="searchParam.discardFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비 목록"
          tableId="equipmentTable"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="신규" icon="add" @btnClicked="openPop" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-manager',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        subProcessCd: null,
        equipmentName: '',
        equipmentTypeCd: '',
        grade: null,
        discardFlag: 'N'
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      discardFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
            style: 'width: 250px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정',
            align: 'center',
            sortable: true,
            style: 'width: 200px',
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            label: '관리부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentLocation',
            field: 'equipmentLocation',
            label: '설비위치',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipGradeName',
            field: 'equipGradeName',
            label: '설비등급',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.equipment.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '설비 상세';
      this.popupOptions.param = {
        equipmentCd: row ? row.equipmentCd : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'./equipmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
